import React from "react";
import i18next from "i18next";
import { BrowserRouter, Route, Routes, Navigate, useLocation } from "react-router-dom";
//import ReactGA from 'react-ga';
import { api_get, handleError } from './API.js';
import { ModalHandler } from "./components/ModalHandler.js";
import { PageLogin } from "./PageLogin.js";
import { PageRecover } from "./PageRecover.js";
import { AdminLogin } from "./components/login/AdminLogin.js";
import { SsoLogin } from "./components/login/SsoLogin.js";
import { AutoLogin } from "./components/login/AutoLogin.js";
import { PageMain } from "./PageMain.js";
import { PageMyActic } from "./PageMyActic.js";
import { PageBooking } from "./PageBooking.js";
import { PageLog } from "./PageLog.js";
import PersonContext from "./PersonContext";
import { PageStatistics } from "./PageStatistics.js";
import { PageSettings } from "./PageSettings.js";
import { findCurrentSubscription } from "./lib/findCurrentSubscription.js";
import { isTest, getDomainCountry } from './config.js';

import "./scss/app.scss";
import { PageChangeMembership } from "./PageChangeMembership.js";
import { PersonalCampaigns } from "./components/settings/PersonalCampaigns.js";
import { Container } from "./components/Components.js";
const TrackingHandler = () => {
  /*
  let personContext = useContext(PersonContext);
  let currentLocation = useLocation();
  let refLocation = useRef({pathname: "", search: ""});
  
  useEffect(() => {
    
    if (typeof refLocation.current === 'undefined' || currentLocation.pathname !== refLocation.current.pathname) { 
      // Location is changed changed
      if (!isTest() && !personContext.nomenu && typeof ga !== 'undefined') {
        ReactGA.pageview(currentLocation.pathname + currentLocation.search);
      }
    }

    refLocation.current = currentLocation;

  }, [currentLocation, refLocation, personContext]);
  */
  return null;
}


export class App extends React.Component {

  nomenu = false;

  constructor(props) {
    super();
    this.state = {
      person: {},
      personCommunication: {},
      center: {},
      centers: [],
      loadingMyactic: false,
      hasMyactic: true,
      myactic: false,
      achievements: false,
      achievementsCompleted: false,
      achievementsCategories: [],
      displayMyActicNavItem: true,
      displayScheduleNavItem: true,
      subscription: false,
      newSubscription: false,
      subscriptions: [],
      clipcards: [],
      accessToken: "",
      loggedIn: false,
      doingAutoLogin: true,
      redirect: false,
      rerender: 0,
      maintenanceMessage: false,
      personalCampaigns: {},
    };

  }

  componentDidMount() {
    
    document.title = i18next.t("SiteName");
    document.querySelector('meta[name="description"]').setAttribute("content", i18next.t("SiteDescription"));

    
    if (typeof window.location.search !== 'undefined') {
      this.nomenu = (new URLSearchParams(window.location.search).get("nomenu")) ? true : false;
    }

    let accessToken = sessionStorage.getItem("accessToken");
    let person = JSON.parse(sessionStorage.getItem("person"));
    let personCommunication = JSON.parse(sessionStorage.getItem("personCommunication"));
    let center = JSON.parse(sessionStorage.getItem("center"));
    //let centers = JSON.parse(sessionStorage.getItem("centers"));
    let newSubscription = JSON.parse(sessionStorage.getItem("newSubscription") || "{}"); // eslint-disable-line
    let subscription = JSON.parse(sessionStorage.getItem("subscription"));
    let subscriptions = JSON.parse(sessionStorage.getItem("subscriptions"));
    let clipcards = JSON.parse(sessionStorage.getItem("clipcards"));
    let personalCampaigns = JSON.parse(sessionStorage.getItem("personalCampaigns"));


    if (accessToken && person) {
      // TODO Check with API and fetch person details
      //console.log("Autologin");
      this.handleSignInSuccess({
        accessToken,
        person,
        personCommunication,
        center,
        //newSubscription,
        subscription,
        subscriptions,
        clipcards,
        personalCampaigns
      });
    } else {
      if (this.state.doingAutoLogin) {
        this.setState({doingAutoLogin: false});
      }
    }

    
  }
  componentDidUpdate() {
    if (this.state.redirect) {
      this.setState({ redirect: false });
    }
  }

  handleSignOut = () => {

    let storageKey = 'AgreeentRequestSent' + this.state.person.personId.externalId;
    sessionStorage.removeItem(storageKey);
    
    //console.log("handleSignOut");
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("person");
    sessionStorage.removeItem("personCommunication");
    sessionStorage.removeItem("center");
    sessionStorage.removeItem("centers");
    sessionStorage.removeItem("newSubscription");
    sessionStorage.removeItem("subscription");
    sessionStorage.removeItem("subscriptions");
    sessionStorage.removeItem("clipcards");
    sessionStorage.removeItem("welcomeShown");
    sessionStorage.removeItem("changeModalShown");
    sessionStorage.removeItem("personalCampaigns");

    this.setState({
      accessToken: "",
      person: {},
      personCommunication: {},
      center: {},
      subscription: false,
      subscriptions: [],
      clipcards: [],
      myactic: false,
      loggedIn: false,
      doingAutoLogin: false,
      redirect: "/",
      currentlyOpenModal: false,
      rerender: this.state.rerender + 1,
      personalCampaigns: {},
    });
  };

  handleSignInSuccess = (resp) => {
    
    //console.log(person);
    // accessToken, person, center
    let person = {
      address: resp.person.address,
      birthday: resp.person.birthday,
      firstName: resp.person.firstName,
      gender: resp.person.gender,
      lastName: resp.person.lastName,
      personId: resp.person.personId,
      personType: resp.person.personType,
      status: resp.person.status,
      socialSecurityNumber: resp.person.socialSecurityNumber,
    };

    sessionStorage.setItem("accessToken", resp.accessToken);
    sessionStorage.setItem("person", JSON.stringify(person));
    sessionStorage.setItem("personCommunication", JSON.stringify(resp.personCommunication));
    sessionStorage.setItem("center", JSON.stringify(resp.center));
    sessionStorage.setItem("subscription", JSON.stringify(resp.subscription));
    sessionStorage.setItem("subscriptions", JSON.stringify(resp.subscriptions));
    sessionStorage.setItem("clipcards", JSON.stringify(resp.clipcards));
    sessionStorage.setItem("personalCampaigns", JSON.stringify(resp.personalCampaigns));

    let hasMyactic = true;
    let displayMyActicNavItem = true;
    if (resp.center.countrycode === 'DE' || resp.center.countrycode === 'AT') {
      hasMyactic = false;
      displayMyActicNavItem = false;
    } else if (getDomainCountry() === 'DE') {
      hasMyactic = false;
      displayMyActicNavItem = false;
    }

    let displayScheduleNavItem = false;

    if (typeof resp.subscription.subscriptionType !== 'undefined' 
        && resp.subscription.subscriptionType !== 'digital' 
        && resp.subscription.state === 'ACTIVE') {
          // The member have an active menership (Not digital/"Anywhere")
          displayScheduleNavItem = true;
    } 
    if (typeof resp.clipcards !== 'undefined' && resp.clipcards && resp.clipcards.length) {
      // The member has active clipcards
      displayScheduleNavItem = true;
    }
    if (typeof resp.subscription.subscriptionType !== 'undefined' 
        && resp.subscription.subscriptionType === 'local' 
        && resp.center.has_gt === false) {
          // The membership is local and the gym has no grouptraining
          displayScheduleNavItem = false;
    }

    let newSubscription = false;
    for (let sub of resp.subscriptions) {
      if (sub.state === 'CREATED' && typeof sub.subState !== 'undefined' && sub.subState === 'CHANGED') {
        newSubscription = sub;
        break;
      }
    }

    if (newSubscription) {
      sessionStorage.setItem("newSubscription", JSON.stringify(newSubscription));
    }

    this.setState(
      {
        person: person,
        personCommunication: resp.personCommunication,
        center: resp.center,
        subscription: resp.subscription,
        newSubscription: newSubscription,
        subscriptions: resp.subscriptions,
        accessToken: resp.accessToken,
        loggedIn: true,
        doingAutoLogin: false,
        hasMyactic: hasMyactic,
        maintenanceMessage: (typeof resp.maintenance && resp.maintenance && resp.maintenanceMessage) ? resp.maintenanceMessage : false,
        displayMyActicNavItem: displayMyActicNavItem,
        displayScheduleNavItem: displayScheduleNavItem,
        redirect: window.location.pathname==='/' ? '/medlemskap' : false,
        personalCampaigns: resp.personalCampaigns,
      }, () => {
        let welcomeShown = sessionStorage.getItem("welcomeShown");
        if (!welcomeShown) {
          this.openModal("welcome");
          sessionStorage.setItem("welcomeShown", true);
        }
      }
    );

    this.getCenters(resp.center.countrycode || '');
  };

  setPersonData = (data) => {
    this.setState(data,
      () => {
        if (typeof data.person !== 'undefined') {
          sessionStorage.setItem("person", JSON.stringify(data.person));
        }
        if (typeof data.personCommunication !== 'undefined') {
          sessionStorage.setItem("personCommunication", JSON.stringify(data.personCommunication));
        }
        if (typeof data.center !== 'undefined') {
          sessionStorage.setItem("center", JSON.stringify(data.center));
        }
        if (typeof data.subscription !== 'undefined') {
          sessionStorage.setItem("subscription", JSON.stringify(data.subscription));
        }
        if (typeof data.subscriptions !== 'undefined') {
          sessionStorage.setItem("subscriptions", JSON.stringify(data.subscriptions));
        }
        if (typeof data.clipcards !== 'undefined') {
          sessionStorage.setItem("clipcards", JSON.stringify(data.clipcards));
        }
        if (typeof data.personalCampaigns !== 'undefined') {
          sessionStorage.setItem("personalCampaigns", JSON.stringify(data.personalCampaigns));
        }
      }
    );
  }
  setSubscriptions = (subscriptions, isChanged=false) => {

    let currentSubscription = findCurrentSubscription(subscriptions);

    let newSubscription = false;
    for (let sub of subscriptions) {
      if (sub.state === 'CREATED' && typeof sub.subState !== 'undefined' && sub.subState === 'CHANGED') {
        newSubscription = sub;
        break;
      }
    }

    if (isChanged && !newSubscription && currentSubscription) {
      newSubscription = currentSubscription;
    }

    this.setState({ subscription: currentSubscription, subscriptions, newSubscription });
    sessionStorage.setItem("newSubscription", JSON.stringify(newSubscription));
    sessionStorage.setItem("subscription", JSON.stringify(currentSubscription));
    sessionStorage.setItem("subscriptions", JSON.stringify(subscriptions));
  };
  setAchievements = (data) => {
    return new Promise((resolve, reject) => {
      if (typeof data.achievements !== 'undefined' && data.achievements) {

        let stateUpdates = {rerender: this.state.rerender + 1};

        stateUpdates.achievements = []; 
        stateUpdates.achievementsCompleted = []; 
        if (typeof data.categories !== 'undefined') {
          stateUpdates.achievementsCategories = data.categories; 
        }
        
        for (let item of data.achievements) {
          if (item.completed && item.reward_acquired) {
            item.percent_done = 100;
            stateUpdates.achievementsCompleted.push(item);
          } else {
            item.percent_done = Math.round((item.progress/item.progress_max) * 100);
            stateUpdates.achievements.push(item);
          }
        }
        
        this.setState(stateUpdates, () => {
          resolve();
        });
  
      } else {
        resolve();
      }
    })
  };

  getCenters = (countrycode) => {
    if (countrycode === '') {
      countrycode = getDomainCountry();
    }
    api_get("/db/centers/" + countrycode)
    //api_get("/persons/"+externalId+"/centers") // This gets only centers accessable by current user
      .then((centers) => {
        sessionStorage.setItem("centers", JSON.stringify(centers));
        this.setState({centers});
      })
      .catch(handleError);
  }
  getMyActic = (force) => { 
    return new Promise((resolve, reject) => {

      if (this.state.loadingMyactic) {
        resolve(false);
        return;
      }

      if (this.state.myactic && (typeof force === 'undefined' || force === false)) {
        resolve(true);
        return;
      }

      if (!this.state.hasMyactic) {
        resolve(true);
        return;
      }

      this.setState({loadingMyactic : true}, () => {
        api_get('/persons/' + this.state.person.personId.externalId + '/myactic')
          .then((resp) => {
            this.setMyActic(resp);
            resolve(true);
          })
          .catch((resp) => {
            reject();
          });
      });
      
    })
    
  }
  setMyActic = (myactic) => {
    if (myactic.hasLoyalty) {
      this.setState({ myactic: myactic, loadingMyactic : false });
    } else {
      this.setState({ loadingMyactic : false, hasMyactic: false, displayMyActicNavItem: false, });
    }
  };

  openModal = (setModal) => {
    this.setState({ currentlyOpenModal: setModal });
  };

  redirect = (path) => {
    this.setState({ redirect: path, rerender: this.state.rerender + 1 });
  };

  render() {

    let personContextValues = {
      accessToken: this.state.accessToken,
      person: this.state.person,
      personCommunication: this.state.personCommunication,
      center: this.state.center,
      centers: this.state.centers,
      subscription: this.state.subscription,
      newSubscription: this.state.newSubscription,
      subscriptions: this.state.subscriptions,
      clipcards: this.state.clipcards,
      myactic: this.state.myactic,
      hasMyactic: this.state.hasMyactic,
      achievements: this.state.achievements,
      achievementsCompleted: this.state.achievementsCompleted,
      achievementsCategories: this.state.achievementsCategories,
      loggedIn: this.state.loggedIn,
      maintenanceMessage: this.state.maintenanceMessage,
      displayMyActicNavItem: this.state.displayMyActicNavItem,
      displayScheduleNavItem: this.state.displayScheduleNavItem,
      currentlyOpenModal: this.state.currentlyOpenModal,
      nomenu: this.nomenu,
      personalCampaigns: this.state.personalCampaigns,
      openModal: this.openModal,
      singOut: this.handleSignOut,
      setSubscriptions: this.setSubscriptions,
      setMyActic: this.setMyActic,
      setAchievements: this.setAchievements,
      setPersonData: this.setPersonData,
      getMyActic: this.getMyActic,
      redirect: this.redirect
    };

    return (
      <PersonContext.Provider
        value={personContextValues}
      >
        <React.Fragment>
          <div id="modal-root" />
          <div id="alert-root" />
          <div id="dropdown-root" className="dropdown" />
          
          <ModalHandler /> 
          

          <BrowserRouter>
            <TrackingHandler />
            {this.state.redirect ? <Navigate to={this.state.redirect} /> : ""}
            <Routes>

              <Route
                exact
                path="/"
                element={ this.state.loggedIn ? (
                    <PageMain />
                  ) : (
                    <PageLogin handleSignInSuccess={this.handleSignInSuccess} />
                  )
                }
              />

              <Route
                exact
                path="glomt-losenord"
                element={<PageRecover />}
              />

              <Route
                exact
                path="sso/:token"
                element={<SsoLogin handleSignInSuccess={this.handleSignInSuccess} redirect={this.redirect} />}
              />

              <Route
                exact
                path=":redirectpath/sso/:token"
                element={<SsoLogin handleSignInSuccess={this.handleSignInSuccess} redirect={this.redirect} />}
              />

              <Route
                exact
                path="myactic"
                element={this.state.loggedIn ? (
                    <PageMyActic />
                  ) : (
                    <PageLogin handleSignInSuccess={this.handleSignInSuccess} />
                  )
                }
              />

              <Route
                exact
                path="boka-pass"
                element={this.state.loggedIn ? (
                    <PageBooking />
                  ) : (
                    <PageLogin handleSignInSuccess={this.handleSignInSuccess} />
                  )
                }
              />

              <Route
                exact
                path="statistik"
                element={this.state.loggedIn ? (
                    <PageStatistics />
                  ) : (
                    this.state.doingAutoLogin ? <AutoLogin /> : <Navigate to={"/"} />
                  )
                }
              />

            <Route path="medlemskap/andra"
              exact
              element={this.state.loggedIn ? (
                  <PageChangeMembership />
                ) : (
                  this.state.doingAutoLogin ? <AutoLogin /> : <Navigate to={"/"} />
                )
              } />

              <Route
                exact path="medlemskap"
                element={this.state.loggedIn ? (
                    <PageSettings />
                  ) : (
                    this.state.doingAutoLogin ? <AutoLogin /> : <Navigate to={"/"} />
                  )
                }
              />
              
              <Route
                path="medlemskap/:section"
                element={this.state.loggedIn ? (
                    <PageSettings />
                  ) : (
                    this.state.doingAutoLogin ? <AutoLogin /> : <Navigate to={"/"} />
                  )
                }
              />

              <Route
                path="aktivitetslogg"
                element={this.state.loggedIn ? (
                    <PageLog />
                  ) : (
                    this.state.doingAutoLogin ? <AutoLogin /> : <Navigate to={"/"} />
                  )
                }
              />

              <Route
                exact
                path="adminlogin/:authcode"
                element={<AdminLogin handleSignInSuccess={this.handleSignInSuccess} redirect={this.redirect} />}
              />

              <Route
                exact path="personliga-kampanjer"
                element={this.state.loggedIn ? (
                    <Container>
                      <PersonalCampaigns />
                    </Container>
                  ) : (
                    this.state.doingAutoLogin ? <AutoLogin /> : <Navigate to={"/"} />
                  )
                }
              />


            </Routes>
          </BrowserRouter>
        </React.Fragment>
      </PersonContext.Provider>
    );
  }
}

export default App;